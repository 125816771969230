import * as React from "react"
import { useTranslation } from "react-i18next"
import { HowToUseVideo } from "../components/document/HowToUseVideo"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getCustomConfig } from "../core/utils/getCustomConfig"

const HowToUseVideoPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo
        title={`${t("str_how_to_use")} | ${getCustomConfig().title_suffix}`}
        favicon={getCustomConfig().favicon}
      />
      <HowToUseVideo />
    </Layout>
  )
}

export default HowToUseVideoPage
