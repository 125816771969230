import React from "react"
import { MainLayout } from "../common/MainLayout"

export type HowToUseVideoProps = {}

export const HowToUseVideo: React.FunctionComponent<HowToUseVideoProps> =
  () => {
    return (
      <MainLayout>
        <div className="c-feature">
          <div className="c-feature__ttl">
            <h3 className="c-feature__ttl--01">NFTの発行方法</h3>
          </div>
        </div>
        <div className="c-main mb-10 py-10 px-5 text-center">
          <video className="max-h-full w-full max-w-screen-lg" controls>
            <source src="/NFT_hack_how_to_use.mp4" type="video/mp4" />
          </video>
        </div>
      </MainLayout>
    )
  }
